<template>
	<main class="registration-page-main" v-if="isThemeFetched">
		<v-row>
			<v-col cols="12">
				<v-container>
					<v-row>
						<v-col cols="12"
							><h1 class="primary-text">Create an account</h1>
							<p class="primary-text">
								Complete the form and WHSA will advise you on
								the best solutions to help your organization.
							</p></v-col
						>
					</v-row>
				</v-container>
			</v-col>
			<v-col cols="12">
				<v-form ref="form">
					<v-container>
						<v-row>
							<v-col cols="12">
								<h4 class="section-title primary-text">
									Account Information
								</h4>
								<v-row>
									<v-col cols="12"
										><v-select
											v-model="userLicense"
											:items="accountLicense"
											:item-text="'name'"
											:item-value="'id'"
											:rules="[rules.required]"
											label="Account License"
											outlined
										></v-select
									></v-col>
								</v-row>
							</v-col>
						</v-row>

						<v-row v-if="userLicense">
							<v-col class="col-12">
								<h4 class="input-title primary-text">Addons</h4>
								<v-select
									v-model="userLicense"
									:items="accountLicense"
									:item-text="'name'"
									:item-value="'id'"
									:rules="[rules.required]"
									label="Addons"
									outlined
								></v-select>
							</v-col>
						</v-row>

						<v-row>
							<v-col cols="6">
								<h4 class="input-title primary-text">
									First name
								</h4>

								<v-text-field
									label="Type user's name"
									v-model="userName"
									:rules="[rules.required]"
									outlined
								></v-text-field>
							</v-col>

							<v-col cols="6">
								<h4 class="input-title primary-text">
									Last name
								</h4>

								<v-text-field
									label="Type user's last name"
									v-model="userLastname"
									:rules="[rules.required]"
									outlined
								></v-text-field>
							</v-col>
						</v-row>

						<v-row>
							<v-col cols="12">
								<h4 class="input-title primary-text">E-mail</h4>

								<v-text-field
									label="Type user's email"
									v-model="userEmail"
									:rules="emailRules"
									outlined
								></v-text-field>
							</v-col>
						</v-row>

						<v-row>
							<v-col cols="12">
								<h4 class="input-title primary-text">
									Password
								</h4>

								<v-text-field
									:append-icon="
										show1 ? 'mdi-eye' : 'mdi-eye-off'
									"
									:rules="[rules.required, rules.min]"
									:type="show1 ? 'text' : 'password'"
									name="input-10-1"
									v-model="userPassword"
									label="Type user's password"
									hint="OK"
									counter
									outlined
									@click:append="show1 = !show1"
								></v-text-field>
							</v-col>
						</v-row>

						<v-row>
							<v-col cols="12">
								<h4 class="input-title primary-text">
									Confirm password
								</h4>

								<v-text-field
									:append-icon="
										show2 ? 'mdi-eye' : 'mdi-eye-off'
									"
									:rules="[
										rules.required,
										rules.min,
										rules.passwordMatch
									]"
									:type="show2 ? 'text' : 'password'"
									name="input-10-1"
									v-model="userConfirmpassword"
									label="Confirm user's password"
									hint="Password Matches"
									counter
									outlined
									@click:append="show2 = !show2"
								></v-text-field>
							</v-col>
						</v-row>
					</v-container>

					<v-container>
						<v-row>
							<v-col cols="12">
								<h2 class="section-title primary-text">
									Company Information
								</h2>
								<v-row>
									<v-col cols="6">
										<h4 class="input-title primary-text">
											Company name
										</h4>

										<v-text-field
											v-model="companyName"
											label="Type company name"
											:rules="[rules.required]"
											outlined
										></v-text-field>
									</v-col>

									<v-col cols="6">
										<h4 class="input-title primary-text">
											Phone number
										</h4>

										<v-text-field
											v-model="companyPhone"
											label="Contact phone"
											:rules="[rules.required]"
											outlined
										></v-text-field>
									</v-col>
								</v-row>

								<v-row>
									<v-col cols="6">
										<h4 class="input-title primary-text">
											Address
										</h4>

										<v-text-field
											v-model="companyAddress"
											label="Company address"
											:rules="[rules.required]"
											outlined
										></v-text-field>
									</v-col>

									<v-col cols="6">
										<h4 class="input-title primary-text">
											City
										</h4>

										<v-text-field
											v-model="companyCity"
											label="Company location"
											:rules="[rules.required]"
											outlined
										></v-text-field>
									</v-col>
								</v-row>

								<v-row>
									<v-col cols="6">
										<h4 class="input-title primary-text">
											State / Province / Region
										</h4>

										<v-text-field
											v-model="companyRegion"
											label="State / Province / Region"
											:rules="[rules.required]"
											outlined
										></v-text-field>
									</v-col>

									<v-col cols="6">
										<h4 class="input-title primary-text">
											ZIP / Postal Code
										</h4>

										<v-text-field
											v-model="companyPostal"
											label="Postal Code"
											:rules="[rules.required]"
											outlined
										></v-text-field>
									</v-col>
								</v-row>

								<v-row>
									<v-col cols="6">
										<h4 class="input-title primary-text">
											Country
										</h4>

										<v-select
											v-model="companyCountry"
											:items="countriesList"
											label="Select your country"
											:rules="[rules.required]"
											outlined
										></v-select>
									</v-col>

									<v-col cols="6">
										<h4 class="input-title primary-text">
											How did you hear about us?
										</h4>

										<v-text-field
											v-model="companyInfo"
											label="e.g. Faceook, Google etc..."
											outlined
										></v-text-field>
									</v-col>
								</v-row>

								<v-row>
									<v-col cols="12">
										<v-btn
											block
											@click="register"
											large
											color="primary"
											rounded
											:loading="isLoading"
											>Create account</v-btn
										>

										<FormMessage v-if="message">
											<template>
												{{ message }}
											</template>
										</FormMessage>
									</v-col>
								</v-row>
							</v-col>
						</v-row>
					</v-container>
				</v-form>
			</v-col>
		</v-row>
	</main>
</template>

<script>
import { post } from "@/util/requests/post";
import { get } from "@/util/requests/get";
import { mapGetters } from "vuex";
import FormMessage from "../components/FormMessage";

export default {
	name: "Registration",
	components: { FormMessage },
	computed: {
		...mapGetters(["isLoading", "isThemeFetched"]),
		selectedLicense() {
			return this.accountLicense.find(el => el.id === this.userLicense);
		},
		organizationId() {
			if (this.$route.query.organizationId !== undefined) {
				return this.$route.query.organizationId;
			} else if (this.organization?.id !== undefined) {
				return this.organization.id;
			} else if (localStorage.getItem("organizationId")) {
				return localStorage.getItem("organizationId");
			} else {
				return 1;
			}
		}
	},
	data() {
		return {
			accountLicense: [],
			countriesList: ["United States", "Canada",  "Australia", "France", "Poland"],
			userLicense: "",
			userName: "",
			userLastname: "",
			userEmail: "",
			userPassword: "",
			userConfirmpassword: "",
			companyName: "",
			companyPhone: "",
			companyAddress: "",
			companyCity: "",
			companyRegion: "",
			companyPostal: "",
			companyCountry: "",
			companyInfo: "",
			show1: false,
			show2: false,
			rules: {
				required: value => !!value || "Required.",
				min: v => v.length >= 8 || "Min 8 characters",
				passwordMatch: v =>
					v === this.userPassword || "Password doesn't match"
			},
			emailRules: [
				v => !!v || "E-mail is required",
				v => /.+@.+/.test(v) || "E-mail must be valid"
			],
			message: ""
		};
	},

	methods: {
		async validate() {
			return await this.$refs.form.validate();
		},
		async register() {
			this.message = "";
			try {
				if ((await this.validate()) === false) {
					this.message = "Check fields";
					return;
				}
				await this.$store.dispatch("setIsLoading", true);
				const response = await post("/signup", {
					email: this.userEmail,
					password: this.userPassword,
					first_name: this.userName,
					last_name: this.userLastname,
					address: this.companyAddress,
					city: this.companyCity,
					state: this.companyRegion,
					zip: this.companyPostal,
					country: this.companyCountry,
					phone: this.phone,
					phone_ext: this.phone_ext,
					license_id: this.selectedLicense.id,
					license_price_id: 1,
					license_data: "",
					license_price_data: "",
					license_quantity: "",
					company_size: "",
					referred_by_email: "",
					description: "",
					internal_notes: "",
					status: "",
					website: "",
					company: "",
					role_in_company: "",
					type: "",
					license_label: "",
					middle_name: ""
				});
				if (response.status === 200 || response.status === 201) {
					await this.$store.dispatch("login", {
						email: this.userEmail,
						password: this.userPassword
					});
					this.$router.push({ path: "/admin" });
				}
			} catch (error) {
				console.error(error);
				this.message = error.response.data.message;
			}
			await this.$store.dispatch("setIsLoading", false);
		},
		async getLicenses() {
			const {
				data: { data }
			} = await get("/licenses");
			this.accountLicense = data;
		}
	},
	async beforeMount() {
		localStorage.setItem("organizationId", this.organizationId);
		await this.$store.dispatch("getOrganizationInfo", this.organizationId);
		await this.$store.dispatch("setThemeColors");

		this.$store.dispatch("setIsThemeFetched", true);
	},

	mounted() {
		this.getLicenses();
	}
};
</script>
